<template>
  <div>
    <div class="row">
      <div class="col-sm-8 offset-sm-2">
        <DataGrid :configuracion="datagrid_config" :data="usuarios" @ready="buscar_botones">
          <button class="btn btn-info" @click="modal_nuevo=true">Agregar usuario</button>
        </DataGrid>
      </div>
    </div>

    <Nuevo v-if="modal_nuevo" :area="area" @update="actualizar_info" @close="cancelar_modales"/>
    <Detalle v-if="modal_detalle" :area="area" :usuario="usuario" @update="actualizar_info" @close="cancelar_modales"/>
  </div>
</template>

<script>
  import DataGrid from '@/components/DataGridV3';

  import Nuevo from './Usuario/Nuevo';
  import Detalle from './Usuario/Detalle';

  import apiAreas from '@/apps/tickets/api/areas';

  export default {
    components: {
      DataGrid, Nuevo, Detalle
    },
    props: {
      area: {
        type: Object,
        default() {
          return {
            id: null,
            usuarios: []
          }
        }
      }
    },
    data() {
      return {
        datagrid_config: {
          name: 'area_usuarios'
          ,selector: false
          ,show_info: false
          ,cols: {
            nombre: 'Nombre'
            ,estatus: 'Estatus'
            ,opciones: {
              text: 'Opciones'
              ,ordeable: false
            }
          }
          ,search: ['nombre','estatus']
          ,mutators: {
            estatus(val) {
              return val == 1 ? 'Activo' : 'Inactivo';
            }
            ,opciones(val, row, vue) {
              return '<div class="text-center">'
                  +  '<img class="mr-2 btn-table btn-areas-usuarios" id="usuario_editar_'+vue.$helper.strRand(10)+'" data-action="editar" data-usuario="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar usuario" title="Editar usuario" />'
                  +  '<img class="btn-table btn-areas-usuarios" data-action="eliminar" id="usuario_eliminar_'+vue.$helper.strRand(10)+'" data-usuario="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar usuario" title="Elimitar usuario" />'
                  +  '</div>';
            }
          }
        },
        usuarios: [],
        usuario: {
          id: null,
          account_id: null,
          nombre: null,
          estatus: null
        },
        modal_nuevo: false,
        modal_detalle: false
      }
    }
    ,mounted() {
      this.usuarios = this.$helper.clone(this.area.usuarios);
    }
    ,methods: {
      buscar_botones() {
        document.querySelectorAll('.btn-areas-usuarios').forEach(btn => {
          btn.removeEventListener('click', this.eventos_btns);
          btn.addEventListener('click', this.eventos_btns);
        })
      }
      ,eventos_btns(e) {
        let accion = e.target.dataset.action;
        let usuario = e.target.dataset.usuario;
        usuario = this.$helper.fromJson(usuario);

        if (accion == 'editar') {
          this.usuario = usuario;
          this.modal_detalle = true;
          return;
        }

        this.eliminar_usuario(usuario);
      }
      ,cancelar_modales() {
        this.modal_detalle = false;
        this.modal_nuevo = false;
        this.usuario = {
          id: null,
          account_id: null,
          nombre: null,
          estatus: null
        }
      }
      ,async eliminar_usuario(usuario) {
        try {
          let res = (await apiAreas.eliminar_usuario(this.area.id, usuario.id)).data;
          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,actualizar_info() {
        this.cancelar_modales();
        this.$emit('update');
      }
    }
    ,watch: {
      area: {
        handler() {
          this.$log.info('se actualiza area');
          this.usuarios = this.area.usuarios;
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss">
  .btn-table {
    width: 20px;
  }
</style>