<template>
  <div>
    <div class="row">
      <div class="col-sm-10 offset-sm-1">
        <DataGrid :configuracion="datagrid_config" :data="etapas" @ready="buscar_botones">
          <button class="btn btn-warning mr-2" @click="$emit('update')">Actualizar</button>
          <button class="btn btn-info" @click="modal_nuevo=true">Agregar etapa</button>
        </DataGrid>
      </div>
    </div>

    <Nuevo v-if="modal_nuevo" :area="area" @update="actualizar_area" @close="cancelar_modales" />
    <Editar v-if="modal_editar" :area="area" :etapa="etapa" @update="actualizar_area" @close="cancelar_modales" />
  </div>
</template>

<script type="text/javascript">
  import DataGrid from '@/components/DataGridV3';

  import apiArea from '@/apps/tickets/api/areas';

  import Nuevo from './Etapa/Nuevo';
  import Editar from './Etapa/Editar';

  export default {
    components: {
      DataGrid, Nuevo, Editar
    }
    ,
    props: {
      area: {
        type: Object,
        default() {
          return {
            id: null,
            etapas: []
          }
        }
      }
    }
    ,data() {
      return {
        datagrid_config: {
          name: 'area_usuarios'
          ,selector: false
          ,show_info: false
          ,ordenar_col: 'posicion'
          ,ordenar_dir: 'asc'
          ,cols: {
            etapa: 'Nombre'
            ,posicion: 'Posicion'
            ,tiempo_vida: 'Tiempo vida'
            ,porcentaje_retraso: 'Porcentaje de retraso'
            ,tipo: 'Tipo'
            ,estatus: 'Estatus'
            ,opciones: {
              text: 'Opciones'
              ,ordeable: false
            }
          }
          ,search: ['etapa','posicion','tiempo_vida','porcentaje_retraso','tipo','estatus']
          ,mutators: {
            tiempo_vida(val, row, vue) {
              val = parseInt(val);
              let tiempo = '';

              if (val > 604799) {
                let semanas = Math.floor(val / 604799);
                val = val - (semanas * 604800);
                tiempo += semanas + ' Semana(s)';
              }

              if (val > 86399) {
                let dias =  Math.floor(val / 86399);
                val = val - (dias * 86400);
                tiempo += (tiempo == '' ? '' : ' y ') + dias +' Día(s)';
              }

              if (val > 3599) {
                let horas = Math.floor(val / 3599);
                val = val - (horas * 3600);
                tiempo += (tiempo == '' ? '' : ' y ')+horas+' Hora(s)';
              }

              if (val > 59) {
                let minutos = Math.floor(val / 59);
                tiempo += (tiempo == '' ? '' : ' y ')+minutos+' Minuto(s)';
              }

              return tiempo;

            },
            porcentaje_retraso(val) {
              return val+'%';
            },
            tipo(val) {
              switch(val) {
                case 0:
                  return 'Etapa normal';
                case 1:
                  return 'Etapa final'
                case 2:
                  return 'Etapa no viable'
              }
            },
            estatus(val) {
              return val == 1 ? 'Activo' : 'Inactivo';
            }
            ,opciones(val, row, vue) {
              return '<div class="text-center">'
                  +  '<img class="mr-2 btn-table btn-areas-etapas" id="etapa_editar_'+vue.$helper.strRand(10)+'" data-action="editar" data-etapa="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar etapa" title="Editar etapa" />'
                  +  '<img class="btn-table btn-areas-etapas" data-action="eliminar" id="etapa_eliminar_'+vue.$helper.strRand(10)+'" data-etapa="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar etapa" title="Elimitar etapa" />'
                  +  '</div>';
            }
          }
        },
        etapas: [],
        etapa: {
          id: null,
          etapa: null,
          posicion: 1,
          tiempo_vida: 3600,
          porcentaje_retraso: 70,
          tipo: 0,
          estatus: 1
        },
        modal_nuevo: false,
        modal_editar: false
      }
    }
    ,mounted() {
      this.etapas = this.$helper.clone(this.area.etapas);
    }
    ,methods: {
      buscar_botones() {
        document.querySelectorAll('.btn-areas-etapas').forEach(btn => {
          btn.removeEventListener('click', this.acciones_btns);
          btn.addEventListener('click', this.acciones_btns);
        })
      }
      ,acciones_btns(e) {
        let etapa = e.target.dataset.etapa;
        etapa = this.$helper.fromJson(etapa);

        if (e.target.dataset.action == 'editar') {
          this.etapa = etapa;
          this.modal_editar = true;
          return;
        }

        this.eliminar_etapa(etapa);
      }
      ,cancelar_modales() {
        this.modal_nuevo = false;
        this.modal_editar = false;
        this.etapa = {
          id: null,
          etapa: null,
          posicion: 1,
          tiempo_vida: 3600,
          porcentaje_retraso: 70,
          tipo: 0,
          estatus: 1
        }
      }
      ,actualizar_area() {
        this.cancelar_modales();

        this.$emit('update');
      }
      ,async eliminar_etapa(etapa) {
        try {
          let res = (await apiArea.eliminar_etapa(this.area.id, etapa.id)).data;
          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
    ,watch: {
      area: {
        handler() {
          this.$log.info('se actualiza area');
          this.etapas = this.area.etapas;
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss">
  .btn-table {
    width: 20px;
  }
</style>