<template>
  <div>
    <div class="row">
      <div class="col-sm-10 offset-sm-1">
        <DataGrid :configuracion="datagrid_config" :data="requerimientos" @ready="buscar_btns">
          <button class="btn btn-warning mr-2" @click="$emit('update')">Recargar</button>
          <button class="btn btn-info" @click="model_nuevo=true">Nuevo requerimiento</button>
        </DataGrid>
      </div>
    </div>

    <Nuevo v-if="model_nuevo||model_editar" :area="area" :req_id="req_id"  @update="actualizar_area" @close="cerrar_modales" />
  </div>
</template>

<script>
  import DataGrid from '@/components/DataGridV3';

  import Nuevo from './Requerimiento/Nuevo';

  import apiArea from '@/apps/tickets/api/areas';

  export default {
    components: {
      DataGrid, Nuevo
    }
    ,props: {
      area: {
        type: Object,
        default() {
          return {
            id: null,
            requerimientos: []
          }
        }
      }
    }
    ,data() {
      return {
        datagrid_config: {
          name: 'area_usuarios'
          ,selector: false
          ,show_info: false
          ,ordenar_col: 'posicion'
          ,ordenar_dir: 'asc'
          ,cols: {
            nombre: 'Nombre'
            ,estatus: 'Estatus'
            ,opciones: {
              text: 'Opciones'
              ,ordeable: false
            }
          }
          ,search: ['nombre','estatus']
          ,mutators: {
            
            estatus(val) {
              return val == 1 ? 'Activo' : 'Inactivo';
            }
            ,opciones(val, row, vue) {
              return '<div class="text-center">'
                  +  '<img class="mr-2 btn-table btn-areas-req" id="req_editar_'+vue.$helper.strRand(10)+'" data-action="editar" data-req="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar requerimiento" title="Editar requerimiento" />'
                  +  '<img class="btn-table btn-areas-req" data-action="eliminar" id="req_eliminar_'+vue.$helper.strRand(10)+'" data-req="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar requerimiento" title="Elimitar requerimiento" />'
                  +  '</div>';
            }
          }
        },
        requerimientos: [],
        req_id: null,
        model_nuevo: false,
        model_editar: false
      }
    }
    ,mounted() {
      this.requerimientos = this.$helper.clone(this.area.requerimientos);
    }
    ,methods: {
      buscar_btns() {
        document.querySelectorAll('.btn-areas-req').forEach(btn => {
          btn.removeEventListener('click', this.acciones_btns);
          btn.addEventListener('click',this.accion_btns)
        })
      }
      ,accion_btns(e) {
        let req = e.target.dataset.req;
        req = this.$helper.fromJson(req);

        if (e.target.dataset.action == 'editar') {
          this.req_id = req.id;
          this.model_editar = true;
          return;
        }

        this.eliminar_req(req);
      }
      ,cerrar_modales() {
        this.model_nuevo = false;
        this.model_editar = false;
        this.req_id = null;
      }
      ,actualizar_area() {
        this.cerrar_modales();

        this.$emit('update');
      }
      ,async eliminar_req(req) {
        try {
          let res = (await apiArea.eliminar_req(this.area.id, req.id)).data;
          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
    ,watch: {
      area: {
        handler() {
          this.$log.info('se actualiza area');
          this.requerimientos = this.$helper.clone(this.area.requerimientos);
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss">
  .btn-table {
    width: 20px;
  }
</style>