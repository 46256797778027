<template>
  <div>
    <div class="row form-group">
      <label for="" class="col-form-label col-sm-2 offset-sm-3">Nombre</label>
      <div class="col-sm-4"><input v-model="area_mod.nombre" type="text" name="" id="" class="form-control"></div>
    </div>
    <div class="row form-group">
      <label for="" class="col-form-label col-sm-2 offset-sm-3">Director</label>
      <div class="col-sm-4"><input v-model="area_mod.director" type="text" name="" id="" class="form-control"></div>
    </div>
    <div class="row form-group">
      <label for="" class="col-form-label col-sm-2 offset-sm-3">Estatus</label>
      <div class="col-sm-4"><BpBSwitch v-model="area_mod.estatus" label="Activo" value="1" /></div>
    </div>
    <div class="row form-group">
      <div class="col-sm-6 offset-sm-3 text-right">
        <button class="btn btn-primary mr-2" @click="guardar_cambios">Guardar</button>
        <button class="btn btn-danger" @click="descargar_cambios">Descartar</button>
      </div>
    </div>
  </div>
</template>

<script>
  import BpBSwitch from '@/components/Form/Switch';

  import apiAreas from '@/apps/tickets/api/areas'

  export default {
    components: {
      BpBSwitch
    },
    props: {
      area: {
        type: Object,
        default() {
          return {
            id: null,
            usuarios: []
          }
        }
      }
    }
    ,data() {
      return {
        area_mod: {
          id: null,
          nombre: null,
          director: null,
        }
      }
    }
    ,mounted() {
      this.area_mod = {
        nombre: this.area.nombre,
        director: this.area.director,
        estatus: this.area.estatus
      };
    }
    ,methods: {
      async guardar_cambios() {
        try {
          let res = null;
          if  (!this.area.id) {
            res = (await apiAreas.crear_area(this.area_mod)).data;
            this.$log.info('res1', res);
            this.$emit('update', res);
            return;
          }
          
          this.$log.info('actualizamos datos');
          res = (await apiAreas.editar_area(this.area.id, this.area_mod)).data;
          this.$log.info('res2', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,descargar_cambios() {
        this.area_mod = {
          nombre: this.area.nombre,
          director: this.area.director,
          estatus: this.area.estatus
        };
      }
    }
  }
</script>

<style lang="scss" scoped>
  .row {
    .col-form-label {
      background-color: #B3B3B3;
    }
  }
</style>