<template>
  <div>
    <Modal :options="{width: '50vw', close: true, esc: false}" @close="$emit('close')">
      <div class="title">Nueva etapa</div>
      <div class="body">
        <div class="row form-group">
          <label for="etapa.etapa" class="col-form-label col-sm-3">Nombre</label>
          <div class="col-sm-9"><input v-model="etapa.etapa" type="text" name="etapa.etapa" id="etapa.etapa" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="etapa.posicion" class="col-form-label col-sm-3">Posicion</label>
          <div class="col-sm-9">
            <select v-model="etapa.posicion" name="etapa.posicion" id="etapa.posicion" class="form-control">
              <option value="1">Al inicio</option>
              <option v-for="tmp_etapa in area.etapas" :value="tmp_etapa.posicion+1">Después de {{ tmp_etapa.etapa }}</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label for="etapa.tiempo_vida" class="col-form-label col-sm-3">Tiempo vida</label>
          <div class="col-sm-9"><input v-model="etapa.tiempo_vida" type="text" name="etapa.tiempo_vida" id="etapa.tiempo_vida" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="etapa.porcentaje_retraso" class="col-form-label col-sm-3">Porcentaje de retraso</label>
          <div class="col-sm-9"><input v-model="etapa.porcentaje_retraso" type="text" name="etapa.porcentaje_retraso" id="etapa.porcentaje_retraso" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="etapa.tipo" class="col-form-label col-sm-3">Tipo</label>
          <div class="col-sm-9">
            <select v-model="etapa.tipo" name="etapa.tipo" id="etapa.tipo" class="form-control">
              <option value="0">Normal</option>
              <option value="1">Última etapa</option>
              <option value="2">No viable</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label for="etapa.estatus" class="col-form-label col-sm-3">Estatus</label>
          <div class="col-sm-9">
            <select v-model="etapa.estatus" name="etapa.estatus" id="etapa.estatus" class="form-control">
              <option value="1">Activo</option>
              <option value="0">Inactivo</option>
            </select>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="agregar_etapa">Agregar etapa</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal';

  import apiArea from '@/apps/tickets/api/areas';

  export default {
    components: {
      Modal
    }
    ,props: {
      area: {
        type: Object,
        default() {
          return {
            id: null,
            etapas: []
          }
        }
      }
    }
    ,data() {
      return {
        etapa: {
          etapa: null,
          posicion: 0,
          tiempo_vida: 3600,
          porcentaje_retraso: 70,
          tipo: 0,
          estatus: 1
        }
      }
    }
    ,mounted() {
      this.etapa.posicion = this.area.etapas.length > 0 ? this.area.etapas[this.area.etapas.length-1].posicion+1 : 1;
    }
    ,methods: {
      async agregar_etapa() {
        try {
          let res = (await apiArea.agregar_etapa(this.area.id, this.etapa)).data;
          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
    ,watch: {
      etapa: {
        handler(val) {
          if (val.porcentaje_retraso > 100)
            this.etapa.porcentaje_retraso = 100;
        }
        ,deep: true
      }
    }
  }
</script>