<template>
  <div>
    <div class="row mt-3 mb-3">
      <h1 class="col-sm-12 text-right">Áreas</h1>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <DataGrid :configuracion="datagrid_config" :data="areas" @ready="buscar_botones">
          <button class="btn btn-warning mr-2" @click="obtener_areas">Recargar</button>
          <button class="btn btn-info mr-2" @click="modal_nuevo=true">Agregar área</button>
        </DataGrid>
      </div>
    </div>

    <Nuevo v-if="modal_nuevo" @up="definir_area_id" @close="cerrar_modales" />
    <Detalle v-if="modal_detalle" :area_id="area.id" @up="cerrar_modal_area_up" @close="cerrar_modales" />
  </div>
</template>

<script type="text/javascript">
  import DataGrid from '@/components/DataGridV3'

  import Nuevo from './Area/Nuevo'
  import Detalle from './Area/Detalle'

  import apiAreas from '@/apps/tickets/api/areas'

  export default {
    components: {
      DataGrid, Nuevo, Detalle
    }
    ,data() {
      return {
        datagrid_config: {
          name: 'areas'
          ,selector: false
          ,show_info: false
          ,cols: {
            nombre: 'Área'
            ,estatus: 'Estatus'
            ,opciones: {
              text: 'Opciones'
              ,ordeable: false
            }
          }
          ,search: ['area','estatus']
          ,mutators: {
            estatus(val) {
              return val == 1 ? 'Activo' : 'Inactivo';
            }
            ,opciones(val, row, vue) {
              return '<div class="text-center"><img class="mr-2 btn-areas" id="area_editar_'+vue.$helper.strRand(10)+'" data-action="editar" data-area="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar área" title="Editar área" />'
                  +  '<img class="btn-areas" data-action="eliminar" id="area_eliminar_'+vue.$helper.strRand(10)+'" data-area="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar área" title="Elimitar área" /></div>';
            }
          }
        }
        ,areas: []
        ,area: {
          id: null
          ,area: null
          ,estatus: 1
          ,usuarios: []
        }
        ,modal_nuevo: false
        ,modal_detalle: false
        ,eventos_agregados: false
      }
    }
    ,mounted() {
      this.obtener_areas();
    }
    ,methods: {
      async obtener_areas() {
        try {
          this.$log.info('obtenemos areas y agregamos eventos');
          // this.eventos_agregados = false;
          this.areas = (await apiAreas.obtener_areas()).data;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerrar_modal_area_up() {
        this.obtener_areas();
        this.cerrar_modal_area();
      }
      ,cerrar_modales() {
        this.modal_nuevo = false;
        this.modal_detalle = false;
        this.area = {
          id: null
          ,area: null
          ,usuarios: []
        }
      }
      ,buscar_botones() {
        let contenedor = document.querySelector('.dataGrid_areas');
        let btns = contenedor.querySelectorAll('.btn-areas');

        btns.forEach(btn => {
          // btn.replaceWith(btn.cloneNode(true));

          btn.removeEventListener('click',this.btns_acciones)

          btn.addEventListener('click',this.btns_acciones)
        })
      }
      ,async btns_acciones(e) {
        try {
          let accion = e.target.dataset.action;
          let area = this.$helper.fromJson(e.target.dataset.area);
          this.$log.info('accion', accion);
          this.$log.info('area', area);

          if (accion == 'editar') {
            if (!area.id)
              area.index = this.$helper.strstrRand(20);

            this.area = area;
            this.modal_detalle = true;
          }else {
            let res = (await apiAreas.eliminar_area(area.id)).data;
            this.$log.info('res', res);
            this.obtener_areas();
          }
        }catch(e) {
          this.$log.error('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,definir_area_id(area) {
        this.obtener_areas();
        this.modal_nuevo = false;

        // setTimeout(() => {
          this.$log.info('se define el area id', area);
          this.area = area;
          this.$log.info('area: ', this.area);
          this.modal_detalle = true;
        // },100);
      }
    }
  }
</script>

<style lang="scss">
  table {
    tbody {
      tr {
        td {
          img {
            width: 20px;
          }
        }

        td:nth-child(2) {
          width: 20%;
        }

        td:nth-child(3) {
          width: 10%;
        }
      }
    }
  }
</style>