<template>
  <div>
    <Modal :options="{width: '40vw'}">
      <div class="title">Nuevo usuario</div>
      <div class="body">
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-3">Usuario</label>
          <div class="col-sm-9">
            <select v-model="usuario_tmp.account_id" name="" id="" class="form-control">
              <option v-for="usuario in usuarios_account" :value="usuario.id">{{ usuario.nombre+'('+usuario.organizacion.nombre+')' }}</option>
            </select>
          </div>
        </div>

        <div class="row form-group">
          <label for="" class="col-form-label col-sm-3">Estatus</label>
          <div class="col-sm-9">
            <BpBSwitch v-model="usuario_tmp.estatus" label="Activo" />
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="crear_usuario">Agregar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar agregar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
  import Modal from '@/components/Modal';
  import BpBSwitch from '@/components/Form/Switch'

  import apiAccount from '@/apps/tickets/api/account';
  import apiAreas from '@/apps/tickets/api/areas';

  export default {
    components: {
      Modal, BpBSwitch
    },
    props: {
      area: {
        type: Object,
        default() {
          return {
            id: null
          }
        }
      }
    },
    data() {
      return {
        usuario_tmp: {
          id: null,
          account_id: 1,
          nombre: null,
          estatus: 1
        },
        usuarios_account: [],
      }
    }
    ,mounted() {
      this.obtener_usuarios_account();
    }
    ,methods: {
      async obtener_usuarios_account() {
        try {
          this.usuarios_account = await this.$helper.cache_store('tickets_account_users', async () => {
            return (await apiAccount.obtener_usuarios()).data;
          });

          this.usuario_tmp.account_id = this.usuarios_account[0].id;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      },
      async crear_usuario() {
        try {
          let usr = this.$helper.clear_null_nodes(this.usuario_tmp);
          let res = (await apiAreas.agregar_usuario(this.area.id, usr)).data;
          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>