import Vue from 'vue'
import store from '@/store'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_ACCOUNT);

export default {
  obtener_usuarios() {
    return http.get(`/usuarios?f[0][column]=estatus&f[0][query_1]=1&f[0][operator]=equal_to`);
  }
}