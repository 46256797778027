<template>
  <div>
    <Modal :options="{width: '80vw', close:true, esc:false}" @close="$emit('close')">
      <div class="title">Área: {{ area.nombre }}</div>
      <div v-if="is_loaded" class="body">
        <div class="row mb-3">
          <div class="col-sm-12">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a :class="css_tab_activo('general')" @click.prevent="tab_activo = 'general'" href="#">General</a>
              </li>
              <li class="nav-item">
                <a :class="css_tab_activo('usuarios')" @click.prevent="tab_activo = 'usuarios'" href="#">Usuarios</a>
              </li>
              <li class="nav-item">
                <a :class="css_tab_activo('etapas')" @click.prevent="tab_activo = 'etapas'" href="#">Etapas</a>
              </li>
              <li class="nav-item">
                <a :class="css_tab_activo('requerimientos')" @click.prevent="tab_activo = 'requerimientos'">Requerimientos</a>
              </li>
            </ul>
          </div>
        </div>

        <div v-if="tab_activo == 'general'" class="row">
          <div class="col-sm-12">
            <General :area="area" @update="obtener_area" />
          </div>
        </div>
        <div v-if="tab_activo == 'usuarios'" class="row">
          <div class="col-sm-12">
            <Usuario :area="area" @update="obtener_area" />
          </div>
        </div>
        <div v-if="tab_activo == 'etapas'" class="row">
          <div class="col-sm-12">
            <Etapa :area="area" @update="obtener_area" />
          </div>
        </div>
        <div v-if="tab_activo == 'requerimientos'" class="row">
          <div class="col-sm-12">
            <Requerimiento :area="area" @update="obtener_area" />
          </div>
        </div>
      </div>
      <div class="footer"></div>
    </Modal>
  </div>
</template>

<script>
  import Modal from '@/components/Modal';

  import General from './Detalle/General';
  import Usuario from './Detalle/Usuario';
  import Etapa from './Detalle/Etapa';
  import Requerimiento from './Detalle/Requerimiento';

  import apiAreas from '@/apps/tickets/api/areas';

  export default {
    components: {
      Modal,General,Usuario,Etapa,Requerimiento
    },
    props: {
      area_id: null
    }
    ,data() {
      return {
        tab_activo: 'general',
        is_loaded: false,
        area: {
          id: null,
          nombre: null,
          usuarios: [],
          etapas: [],
          requerimientos: []
        }
      }
    }
    ,mounted() {
      this.obtener_area();
    }
    ,methods: {
      async obtener_area() {
        try {
          this.$log.info('cargamos las areas 2');
          this.area = (await apiAreas.obtener_area(this.area_id)).data;
          this.is_loaded = true;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      },
      css_tab_activo(tab) {
        let clases = 'nav-link';

        if (this.tab_activo == tab)
          clases += ' active';

        return clases;
      }
    }
    ,computed: {

    }
  }
</script>